import moment from "moment";

export class FFStats {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFStats";
    this.scene = "Full-Time Stats";
    this.data = [];
  }

  getTotalPosssession(sequences, game) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total_home = 0;
    let total_away = 0;
    sequences
      .filter((s) => s.team === game?.home_team?._id)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total_home += end_time - start_time;
      });

    sequences
      .filter((s) => s.team === game?.away_team?._id)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total_away += end_time - start_time;
      });
    let home_percent = parseInt((total_home / (total_home + total_away)) * 100);

    return {
      home: home_percent || 0,
      away: 100 - home_percent || 0,
    };
  }

  getSetTotals(sequences, team) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total = 0;
    let complete = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total += 1;
        complete += s?.status === "complete" ? 1 : 0;
      });
    return {
      total,
      complete,
      percent: parseInt((complete / total) * 100) || 0,
    };
  }
  getTotalMetres(sequences, team) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total +=
          Math.abs(
            Math.round(
              (Math.max(82, Math.min(933, s?.tackles?.at(-1)?.x)) -
                Math.max(82, Math.min(933, s?.tackles?.[0]?.x))) /
                7
            )
          ) || 0;
      });
    return total;
  }

  preview({ options }) {
    let score = this.getScore(options.game);
    this.onAir = false;
    this.options = options;
    this.data = [];

    this.scene = options.half === 1 ? "Half-Time Stats" : "Full-Time Stats";

    this.data.push({
      name: "Half Text",
      value: { text: "Half Time" },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Home Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.home_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.away_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Score",
      value: {
        text: score.home_score || 0,
      },
    });
    this.data.push({
      name: "Away Score",
      value: {
        text: score.away_score || 0,
      },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Competition Secondary Badge",
      value: {
        image: "${user}" + options.game?.competition?.secondary_badge || "",
      },
    });

    this.data.push({
      name: "Stadium",
      value: { text: options.game?.stadium?.name || "" },
    });

    this.data.push({
      name: "Location",
      value: { text: options.game?.stadium?.location || "" },
    });
    
    this.data.push({
      name: "Period",
      value: { text: options.half },
    });

    if (!options.game?.oval_ID) {
      let home_stats = options.game?.home_player_stats || [];
      let away_stats = options.game?.away_player_stats || [];

      let possession = this.getTotalPosssession(
        [
          ...(options.game?.home_sets || []),
          ...(options.game?.away_sets || []),
        ],
        options.game
      );

      this.data.push({
        name: "Possession Home Value",
        value: { text: (possession?.home || 0) + "%" },
      });

      this.data.push({
        name: "Possession Away Value",
        value: { text: (possession?.away || 0) + "%" },
      });

      let home_sets = this.getSetTotals(
        [
          ...(options.game?.home_sets || []),
          ...(options.game?.away_sets || []),
        ],
        options?.game?.home_team?._id
      );

      let away_sets = this.getSetTotals(
        [
          ...(options.game?.home_sets || []),
          ...(options.game?.away_sets || []),
        ],
        options?.game?.away_team?._id
      );

      this.data.push({
        name: "Completed Sets Home Value",
        value: {
          text: (home_sets?.complete || 0) + "/" + (home_sets?.total || 0),
        },
      });

      this.data.push({
        name: "Completed Sets Away Value",
        value: {
          text: (away_sets?.complete || 0) + "/" + (away_sets?.total || 0),
        },
      });

      this.data.push({
        name: "Completion Rate Home Value",
        value: {
          text: (home_sets?.percent || 0) + "%",
        },
      });

      this.data.push({
        name: "Completion Rate Away Value",
        value: {
          text: (away_sets?.percent || 0) + "%",
        },
      });

      this.data.push({
        name: "Tackles Made Home Value",
        value: {
          text: Object.keys(home_stats).reduce(function (previous, key) {
            return previous + (home_stats[key]?.["Tackles"] || 0);
          }, 0),
        },
      });

      this.data.push({
        name: "Tackles Made Away Value",
        value: {
          text: Object.keys(away_stats).reduce(function (previous, key) {
            return previous + (away_stats[key]?.["Tackles"] || 0);
          }, 0),
        },
      });

      this.data.push({
        name: "Tackles Missed Home Value",
        value: {
          text: Object.keys(home_stats).reduce(function (previous, key) {
            return previous + (home_stats[key]?.["Missed Tackles"] || 0);
          }, 0),
        },
      });

      this.data.push({
        name: "Tackles Missed Away Value",
        value: {
          text: Object.keys(away_stats).reduce(function (previous, key) {
            return previous + (away_stats[key]?.["Missed Tackles"] || 0);
          }, 0),
        },
      });

      this.data.push({
        name: "Metres Gained Home Value",
        value: {
          text:
            this.getTotalMetres(
              [
                ...(options.game?.home_sets || []),
                ...(options.game?.away_sets || []),
              ],
              options.game?.home_team?._id
            ) || 0,
        },
      });

      this.data.push({
        name: "Metres Gained Away Value",
        value: {
          text:
            this.getTotalMetres(
              [
                ...(options.game?.home_sets || []),
                ...(options.game?.away_sets || []),
              ],
              options.game?.away_team?._id
            ) || 0,
        },
      });

      this.data.push({
        name: "Carries Home Value",
        value: {
          text: Object.keys(home_stats).reduce(function (previous, key) {
            return previous + (home_stats[key]?.["Carries"] || 0);
          }, 0),
        },
      });

      this.data.push({
        name: "Carries Away Value",
        value: {
          text: Object.keys(away_stats).reduce(function (previous, key) {
            return previous + (away_stats[key]?.["Carries"] || 0);
          }, 0),
        },
      });

      this.data.push({
        name: "Handling Errors Home Value",
        value: {
          text: Object.keys(home_stats).reduce(function (previous, key) {
            return previous + (home_stats[key]?.["Handling errors"] || 0);
          }, 0),
        },
      });

      this.data.push({
        name: "Handling Errors Away Value",
        value: {
          text: Object.keys(away_stats).reduce(function (previous, key) {
            return previous + (away_stats[key]?.["Handling errors"] || 0);
          }, 0),
        },
      });
    } else {
      let home_stats = options.game?.home_team_stats || [];
      let away_stats = options.game?.away_team_stats || [];

      this.data.push({
        name: "Possession Home Value",
        value: {
          text:
            (Math.round(home_stats?.possession?.percentPossession) || 0) + "%",
        },
      });

      this.data.push({
        name: "Possession Away Value",
        value: {
          text:
            100 -
            (Math.round(home_stats?.possession?.percentPossession) || 0) +
            "%",
        },
      });

      this.data.push({
        name: "Home Tries",
        value: {
          text: home_stats?.scoring?.tryScored || 0,
        },
      });

      this.data.push({
        name: "Away Tries",
        value: {
          text: away_stats?.scoring?.tryScored || 0,
        },
      });

      this.data.push({
        name: "Conversions Home Value",
        value: {
          text: home_stats?.scoring?.conversion || 0,
        },
      });

      this.data.push({
        name: "Conversions Away Value",
        value: {
          text: away_stats?.scoring?.conversion || 0,
        },
      });

      this.data.push({
        name: "Penalties Home Value",
        value: {
          text: home_stats?.discipline?.penaltyConceded || 0,
        },
      });

      this.data.push({
        name: "Penalties Away Value",
        value: {
          text: away_stats?.discipline?.penaltyConceded || 0,
        },
      });

      this.data.push({
        name: "Clean Breaks Home Value",
        value: {
          text: home_stats?.attack?.cleanBreak || 0,
        },
      });

      this.data.push({
        name: "Clean Breaks Away Value",
        value: {
          text: away_stats?.attack?.cleanBreak || 0,
        },
      });

      this.data.push({
        name: "Tackles Home Value",
        value: {
          text: home_stats?.defence?.tackle || 0,
        },
      });

      this.data.push({
        name: "Tackles Away Value",
        value: {
          text: away_stats?.defence?.tackle || 0,
        },
      });

      this.data.push({
        name: "Tackles Missed Home Value",
        value: {
          text: home_stats?.defence?.missedTackle || 0,
        },
      });

      this.data.push({
        name: "Tackles Missed Away Value",
        value: {
          text: away_stats?.defence?.missedTackle || 0,
        },
      });

      this.data.push({
        name: "Turnovers Conceded Home Value",
        value: {
          text: home_stats?.attack?.totalTurnovers || 0,
        },
      });

      this.data.push({
        name: "Turnovers Conceded Away Value",
        value: {
          text: away_stats?.attack?.totalTurnovers || 0,
        },
      });

      this.data.push({
        name: "Scrums Won Home Value",
        value: {
          text: home_stats?.scrum?.scrumsWon || 0,
        },
      });

      this.data.push({
        name: "Scrums Won Away Value",
        value: {
          text: away_stats?.scrum?.scrumsWon || 0,
        },
      });

      this.data.push({
        name: "Lineouts Won Home Value",
        value: {
          text: home_stats?.lineout?.wonClean || 0,
        },
      });

      this.data.push({
        name: "Lineouts Won Away Value",
        value: {
          text: away_stats?.lineout?.wonClean || 0,
        },
      });

      this.data.push({
        name: "Lineouts Lost Home Value",
        value: {
          text: home_stats?.lineout?.lostClean || 0,
        },
      });

      this.data.push({
        name: "Lineouts Lost Away Value",
        value: {
          text: away_stats?.lineout?.lostClean || 0,
        },
      });
      this.data.push({
        name: "Defenders Beaten Home Value",
        value: {
          text: home_stats?.attack?.defenderBeaten || 0,
        },
      });
      this.data.push({
        name: "Defenders Beaten Away Value",
        value: {
          text: away_stats?.attack?.defenderBeaten || 0,
        },
      });
      this.data.push({
        name: "Offloads Home Value",
        value: {
          text: home_stats?.attack?.offload || 0,
        },
      });
      this.data.push({
        name: "Offloads Away Value",
        value: {
          text: away_stats?.attack?.offload || 0,
        },
      });
      this.data.push({
        name: "Zone A Possession",
        value: {
          text:
            (home_stats?.possession?.percentPossessionZoneA || 0) +
            (away_stats?.possession?.percentPossessionZoneD || 0),
        },
      });
      this.data.push({
        name: "Zone B Possession",
        value: {
          text:
            (home_stats?.possession?.percentPossessionZoneB || 0) +
            (away_stats?.possession?.percentPossessionZoneC || 0),
        },
      });
      this.data.push({
        name: "Zone C Possession",
        value: {
          text:
            (home_stats?.possession?.percentPossessionZoneC || 0) +
            (away_stats?.possession?.percentPossessionZoneB || 0),
        },
      });
      this.data.push({
        name: "Zone D Possession",
        value: {
          text:
            (home_stats?.possession?.percentPossessionZoneD || 0) +
            (away_stats?.possession?.percentPossessionZoneA || 0),
        },
      });
    }
    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    clearTimeout(this.to);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);

    return { home_score, away_score };
  }
}
