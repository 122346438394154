import React, { useState, useEffect, useContext, useCallback } from "react";
import useLocalStorage from "../Hooks/useLocalStorage";

const UIContext = React.createContext([{}, () => {}]);
const VIEW_CONSTS = {
  matchSelect: 0,
  live: 1,
  formation: 2,
  stats: 3,
  clockEdit: 4,
  all_graphics: 5,
  penalty: 6,
  tackle: 7,
  bigScreen: 8,
  fruit: 9,
};

const MODE_CONSTS = {
  live: 0,
  matchChoice: 1,
};

const UIProvider = (props) => {
  const [view, setView] = useState(VIEW_CONSTS.matchSelect);
  const [mode, setMode] = useState(MODE_CONSTS.live);
  const [goal, setGoal] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [editPlayer, setEditPlayer] = useState();
  const [uiView, setUIView] = useState(0);
  const [penaltyTime, setPenaltyTime] = useState();
  const [tryTimeStatus, setTryTimeStatus] = useState();
  const [tryTime, setTryTime] = useState();
  const [penaltyTimeStatus, setPenaltyTimeStatus] = useState();
  const [popout, setPopout] = useLocalStorage("hyper-rugby-popout", []);
  const [scOnAir, setSCOnAir] = useState();

  return (
    <UIContext.Provider
      value={{
        view,
        setView,
        selectedTeam,
        setSelectedTeam,
        goal,
        setGoal,
        mode,
        setMode,
        editPlayer,
        setEditPlayer,
        uiView,
        setUIView,
        setTryTime,
        tryTime,
        setPenaltyTime,
        penaltyTime,
        tryTimeStatus,
        setTryTimeStatus,
        setPenaltyTimeStatus,
        penaltyTimeStatus,
        popout,
        setPopout,
        setSCOnAir, 
        scOnAir
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};

export { UIContext, UIProvider, VIEW_CONSTS, MODE_CONSTS };
