export class PlayerSeasonStat {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PlayerSeasonStat";
    this.scene = "Player Season Stat";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    let rugby_type = "";
    this.data.push({
      name: "Shirt Number",
      value: {
        text:
          (rugby_type === "union"
            ? options.player.order
            : options.player.shirt_number) || "",
      },
    });

    this.data.push({
      name: "Position",
      value: {
        text: options.player?.squad?.position || "",
      },
    });
    this.data.push({
      name: "First Name",
      value: {
        text: options.player.first_name || "",
      },
    });
    this.data.push({
      name: "Last Name",
      value: {
        text: options.player.last_name || "",
      },
    });
    this.data.push({
      name: "First Name Uppercase",
      value: {
        text: options.player.first_name_upper || "",
      },
    });
    this.data.push({
      name: "Last Name Uppercase",
      value: {
        text: options.player.last_name_upper || "",
      },
    });
    this.data.push({
      name: "Full Name",
      value: {
        text:
          (options.player.first_name || "") +
          " " +
          (options.player.last_name || ""),
      },
    });
    this.data.push({
      name: "Image",
      value: {
        image: "${user}" + (options?.player?.image || ""),
      },
    });
    this.data.push({
      name: "Subtitle",
      value: {
        text: "",
      },
    });
    this.data.push({
      name: "Team Type",
      value: {
        text:
          options.team?._id === options.game?.home_team?._id
            ? "home"
            : options.team?._id === options.game?.away_team?._id
            ? "away"
            : "",
      },
    });
    
    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name || "",
      },
    });

    this.data.push({
      name: "Goal Time",
      value: {
        text: "",
      },
    });

    this.data.push({
      name: "Subtext",
      value: {
        text: options.subtext,
      },
    });

    this.data.push({
      name: "Stat Value",
      value: {
        text: options.value,
      },
    });

    this.data.push({
      name: "Stat Category",
      value: {
        text: options.category,
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });

    let kit =
      options.team?._id === options.game?.home_team?._id
        ? options.game?.home_team_kit
        : options.game?.away_team_kit;

    this.data.push({
      name: "Team Kit BG Colour",
      value: {
        colour: options.team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour",
      value: {
        colour: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Team Kit BG Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
