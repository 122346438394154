import { Clock } from "./Clock";
import { MatchID } from "./MatchID";
import { Name } from "./Name";
import { Versus } from "./Versus";
import { Card } from "./Card";
import { Lineup } from "./Lineup";
import { PlayerScored } from "./PlayerScored";
import { Substitution } from "./Substitution";
import { Score } from "./Score";
import { Penalties } from "./Penalties";
import { TwoSubstitution } from "./TwoSubstitution";
import { ThreeSubstitution } from "./ThreeSubstitution";
import { Formation } from "./Formation";
import { FormationHeads } from "./FormationHeads";
import { POTM } from "./PlayerOfTheMatch";
import { FFScore } from "./FFScore";
import { TeamStat } from "./TeamStat";
import { PlayerStat } from "./PlayerStat";
import { TeamTicker } from "./TeamTicker";
import { PlayerSeasonStat } from "./PlayerSeasonStat";
import { FFStats } from "./FFStats";
import { Standings } from "./Standings";
import { DoubleLineupSubs } from "./DoubleLineupSubs";
import { FormationForwards } from "./FormationForwards";
import { FormationBacks } from "./FormationBacks";
import { FormationDoubleSubs } from "./FormationDoubleSubs";
import { Officials } from "./Officials";
import { WinPrediction } from "./WinPrecition";
import { RefDecision } from "./RefDecision";
import { Try } from "./Try";
import { DropGoal } from "./DropGoal";
import { Conversion } from "./Conversion";
import { PenaltyGoal } from "./PenaltyGoal";
import { PenaltyTry } from "./PenaltyTry";
import { ShotClock } from "./ShotClock";
import { ManualShotClock } from "./ManualShotClock";
import { Advantage } from "./Advantage";
import { SinBin } from "./SinBin";

export default function graphics({
  preview,
  animate,
  update,
  animateOff,
  triggerAnimation,
  clearPreview,
}) {
  return [
    new MatchID({ preview, animate, update, animateOff }),
    new Versus({ preview, animate, update, animateOff, triggerAnimation }),
    new Clock({ preview, animate, update, animateOff, triggerAnimation }),
    new Name({ preview, animate, update, animateOff, triggerAnimation }),
    new Card({ preview, animate, update, animateOff, triggerAnimation }),
    new Lineup({ preview, animate, update, animateOff, triggerAnimation }),
    new PlayerScored({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new Substitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new Score({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new Penalties({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new TwoSubstitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new ThreeSubstitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Formation({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FormationHeads({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new POTM({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FFScore({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new TeamStat({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new PlayerStat({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new TeamTicker({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new PlayerSeasonStat({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FFStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Standings({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new DoubleLineupSubs({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FormationForwards({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FormationBacks({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FormationDoubleSubs({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Officials({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new WinPrediction({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new RefDecision({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Try({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new DropGoal({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Conversion({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }), new PenaltyGoal({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new PenaltyTry({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new ShotClock({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new ManualShotClock({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Advantage({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new SinBin({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
  ];
}
