export class PenaltyGoal {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Penalty Goal";
    this.scene = "Penalty Goal";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Team Type",
      value: {
        text:  options.team?._id === options.game?.home_team?._id
        ? 'home'
        : options.team?._id === options.game?.away_team?._id ? 'away' : '',
      },
    });
   
    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });
    this.data.push({
      name: "Team Third Badge",
      value: {
        image: "${user}" + options.team?.variables?.third_badge || "",
      },
    });
    let kit =
      options.team?._id === options.game?.home_team?._id
        ? options.game?.home_team_kit
        : options.game?.away_team_kit;

    this.data.push({
      name: "Team Kit BG Colour",
      value: {
        colour: options.team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour",
      value: {
        colour: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Team Kit BG Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.data = [];
  debugger
    this.data.push({
      name: "Team Type",
      value: {
        text:  options.team?._id === options.game?.home_team?._id
        ? 'home'
        : options.team?._id === options.game?.away_team?._id ? 'away' : '',
      },
    });
   
    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });
    this.data.push({
      name: "Team Third Badge",
      value: {
        image: "${user}" + options.team?.variables?.third_badge || "",
      },
    });
    let kit =
      options.team?._id === options.game?.home_team?._id
        ? options.game?.home_team_kit
        : options.game?.away_team_kit;

    this.data.push({
      name: "Team Kit BG Colour",
      value: {
        colour: options.team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour",
      value: {
        colour: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Team Kit BG Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
