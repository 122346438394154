// const LEAGUE_ORDER = {
//   row_1: [5, 1, 2],
//   row_2: [3, 4],
//   row_3: [6, 7],
//   row_4: [11, 13, 12],
//   row_5: [8, 9, 10],
// };

const LEAGUE_ORDER = {
  row_1: [1],
  row_2: [5, 4, 3, 2],
  row_3: [7, 6],
  row_4: [10, 9, 8],
  row_5: [12, 11],
  row_6: [13],
};

// const UNION_ORDER = {
//   row_1: [1, 2, 3],
//   row_2: [4, 5],
//   row_3: [6, 8, 7],
//   row_4: [9, 10],
//   row_5: [12, 13],
//   row_6: [11, 15, 14],
// };

const UNION_ORDER = {
  row_1: [1, 2, 3, 4, 5, 6, 7, 8],
  row_2: [9, 10, 11, 12,13, 14, 15],
};


export class TeamTicker {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TeamTicker";
    this.scene = "Team Ticker";
    this.data = [];
    this.page = 1;
  }

  preview({ options }) {
    if (options.start) {
      this.page = 1;
    }
    this.onAir = false;
    this.data = [];
    this.options = options;
   
    let rugby_type = options?.game?.competition?.competition_type;
    let orders = rugby_type === "league" ? LEAGUE_ORDER : UNION_ORDER;
    new Array(8).fill(1).forEach((_, index) => {
      let row = index + 1;

      let player = options.squads?.find(
        (s) => s.order === orders?.["row_" + this.page]?.[index]
      );

      this.data.push({
        name: "First Name " + row,
        value: {
          text: player?.squad?.player?.first_name || "",
        },
      });
      this.data.push({
        name: "Last Name " + row,
        value: {
          text: player?.squad?.player?.last_name || "",
        },
      });
      this.data.push({
        name: "First Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.first_name_upper || "",
        },
      });
      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.last_name_upper || "",
        },
      });
      this.data.push({
        name: "Short Name " + row,
        value: {
          text:
            player?.squad?.player?.short_name ||
            player?.squad?.player?.last_name ||
            "",
        },
      });
      this.data.push({
        name: "Short Name Uppercase " + row,
        value: {
          text:
            player?.squad?.player?.short_name_upper ||
            player?.squad?.player?.last_name_upper ||
            "",
        },
      });
      this.data.push({
        name: "Full Name " + row,
        value: {
          text:
            (player?.squad?.player?.first_name || "") +
            " " +
            (player?.squad?.player?.last_name || ""),
        },
      });
      this.data.push({
        name: "Full Name Uppercase" + row,
        value: {
          text:
            (player?.squad?.player?.first_name_upper || "") +
            " " +
            (player?.squad?.player?.last_name_upper || ""),
        },
      });
      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      
      this.data.push({
        name: "Image " + row,
        value: {
          image: "${user}" + (player?.squad?.player?.image || ""),
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text:
            (rugby_type === "union"
              ? player?.order
              : player?.squad?.shirt_number) || "",
        },
      });
    });

    this.data.push({
      name: "Team Type",
      value: {
        text:
          options.team?._id === options.game?.home_team?._id
            ? "home"
            : options.team?._id === options.game?.away_team?._id
            ? "away"
            : "",
      },
    });
    
    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name,
      },
    });

    this.data.push({
      name: "Team Short Name",
      value: {
        text: options.team?.short_name,
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Team Video Badge",
      value: {
        video: options.team?.variables?.video_badge,
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.onAir_scene = this.scene;
    this.sendAnimate(this.scene, this.data);

    this.page++;
    this.options.start = false;
    this.preview({ options: this.options });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.onAir_scene, []);
    this.page = 1;
  }
}
