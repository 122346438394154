import moment from "moment";

export class SinBin {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "LTSINBIN";
    this.home_scene = "Home Sin Bin";
    this.away_scene = "Away Sin Bin";
    this.data = [];

  }

  async preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.scene = options.team?._id === options.game?.home_team?._id
    ? this.home_scene
    : options.team?._id === options.game?.away_team?._id
    ? this.away_scene
    : "";

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
        name: "Team Type",
        value: {
          text:
            options.team?._id === options.game?.home_team?._id
              ? "home"
              : options.team?._id === options.game?.away_team?._id
              ? "away"
              : "",
        },
      });

    
        try {
          let game_mins =
            options?.clockType === "manual"
              ? parseInt(document.getElementById("manual_minutes").innerText)
              : parseInt(document.getElementById("stadium_minutes").innerText);

          let game_secs =
            options?.clockType === "manual"
              ? parseInt(document.getElementById("manual_seconds").innerText)
              : parseInt(document.getElementById("stadium_seconds").innerText);

          let duration = moment.duration({
            minutes: game_mins,
            seconds: game_secs,
          });
          
          let duration_2 = moment.duration({
            minutes: options?.player?.sinbins?.at(-1)?.game_time_minutes,
            seconds: options?.player?.sinbins?.at(-1)?.game_time_seconds,
          });

          let total_sinbin = moment.duration({ minutes: 10 });

          let diff = total_sinbin.subtract(duration.subtract(duration_2));

          let future = moment().add(diff);

          
    this.data.push({
        name: "Clock",
        value: {
        action: "start",
        time:((diff?.minutes() * 60) + diff?.seconds()) 
        }
      });
      

                 
      this.data.push({
        name: "Game Time Minutes",
        value: {
        text:options?.player?.sinbins?.at(-1)?.game_time_minutes  ,
        }
      });
      this.data.push({
        name: "Game Time Seconds",
        value: {
        text:options?.player?.sinbins?.at(-1)?.game_time_seconds ,
        }
      });

        } catch (err) {}
      
        this.sendPreview(this.scene, 100, this.data, "IN");
  }

   
  animate({ options }) {
    this.onAir = true;
    this.data = [];
    this.scene = options.team?._id === options.game?.home_team?._id
    ? this.home_scene
    : options.team?._id === options.game?.away_team?._id
    ? this.away_scene
    : "";
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
        name: "Team Type",
        value: {
          text:
            options.team?._id === options.game?.home_team?._id
              ? "home"
              : options.team?._id === options.game?.away_team?._id
              ? "away"
              : "",
        },
      });


        try {
          let game_mins =
            options?.clockType === "manual"
              ? parseInt(document.getElementById("manual_minutes").innerText)
              : parseInt(document.getElementById("stadium_minutes").innerText);

          let game_secs =
            options?.clockType === "manual"
              ? parseInt(document.getElementById("manual_seconds").innerText)
              : parseInt(document.getElementById("stadium_seconds").innerText);

          let duration = moment.duration({
            minutes: game_mins,
            seconds: game_secs,
          });
          
          let duration_2 = moment.duration({
            minutes: options?.player?.sinbins?.at(-1)?.game_time_minutes,
            seconds: options?.player?.sinbins?.at(-1)?.game_time_seconds,
          });

          let total_sinbin = moment.duration({ minutes: 10 });

          let diff = total_sinbin.subtract(duration.subtract(duration_2));

          let future = moment().add(diff);

          
    this.data.push({
        name: "Clock",
        value: {
        action: "start",
        time:((diff?.minutes() * 60) + diff?.seconds()) 
        }
      });

      this.data.push({
        name: "Game Time Minutes",
        value: {
        text:options?.player?.sinbins?.at(-1)?.game_time_minutes  ,
        }
      });
      this.data.push({
        name: "Game Time Seconds",
        value: {
        text:options?.player?.sinbins?.at(-1)?.game_time_seconds ,
        }
      });
        } catch (err) {}
      


      this.sendAnimate(this.scene, this.data);
    }

    animateOff({ options }) {
      this.onAir = false;
      debugger
      this.scene = options.team?._id === options.game?.home_team?._id
    ? this.home_scene
    : options.team?._id === options.game?.away_team?._id
    ? this.away_scene
    : "";
      this.sendAnimateOff(this.scene, []);
    }
}
