export class ManualShotClock {
    constructor({ animate, preview, update, animateOff }) {
      this.onAir = false;
      this.sendAnimate = animate;
      this.sendAnimateOff = animateOff;
      this.sendPreview = preview;
      this.sendUpdate = update;
      this.name = "Manual Shot Clock";
      this.scene = "Manual Shot Clock";
      this.data = [];
    }
  
    preview({ options }) {
      this.onAir = false;
      this.sendPreview(this.scene, 50, this.data, "IN");
    }
    animate({ options }) {
      this.onAir = true;
      this.data = [
        {
          name: "Clock",
          value: {
            text: options.clock || "",
          },
        },
        {
          name: "Type",
          value: {
            text: options.type || "",
          },
        },
      ];
      this.sendAnimate(this.scene, this.data);
    }
    animateOff({ options }) {
      this.onAir = false;
      this.sendAnimateOff(this.scene, []);
    }
    update({ options }) {
      this.sendUpdate(this.scene, [
        { name: "Clock", value: { text: options.clock } },
      ]);
    }
  }
  