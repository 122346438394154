const UNION_POSITIONS = [
    "LOOSEHEAD PROP",
    "HOOKER",
    "TIGHTHEAD PROP",
    "SECOND ROW",
    "SECOND ROW",
    "FLANKER",
    "FLANKER",
    "NUMBER EIGHT",
    "SCRUM HALF",
    "FLY HALF",
    "LEFT WING",
    "INSIDE CENTRE",
    "OUTSIDE CENTRE",
    "RIGHT WING",
    "FULL BACK",
  ]

  export function getPosition({order}) {
    return  UNION_POSITIONS?.[order-1] ?? 'REPLACEMENT'
  }