import React, { useContext, useEffect, useState } from "react";
import Game from "../Game/Game";
import RightSideBar from "../RightSideBar/RightSideBar";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import TeamNames from "../TopBar/TeamNames";
import Goal from "../TopBar/Goal";
import { UIContext } from "../../contexts/UIContext";
import ClockEdit from "../ClockEdit/ClockEdit";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Goals from "../Goals/Goals";
import Penalties from "../Penalties/Penalties";
import ServerStatus from "../ServerStatus/ServerStatus";
import { APIContext } from "../../contexts/APIContext";
import Bigscreen from "../Bigscreen/Bigscreen";
import Scores from "../Scores/Scores";
import Attendance from "../Popup/Attendance";
import RightSideBarSky from "../RightSideBar/RightSideBarSky";
import TableView from "../Tables/TableView";
import Overall from "../KickPredictor/Overall";
import useLocalStorage from "../../Hooks/useLocalStorage";
import SeasonRankings from "../SeasonRankings/SeasonRankings";
import { getPosition } from "../../utils/positions";
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        stats
        stories
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          short_name
          short_name_upper
          nationality {
            _id
            opta_code
            opta_name
            name
            code
          }
          image
          stories
          oval_ID
          opta_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      yellow_card_time
      red_card_time
      red_card_seconds
      yellow_card_period
      red_card_period
      sub_order
      hia
      subbed
      captain
      sinbins
    }
    away {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        stats
        stories
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          short_name
          short_name_upper
          nationality {
            _id
            opta_code
            opta_name
            name
            code
          }
          image
          stories
          oval_ID
          opta_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      yellow_card_time
      red_card_time
      red_card_seconds
      yellow_card_period
      red_card_period
      red_card
      sub_order
      hia
      subbed
      captain
      sinbins
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      oval_ID
      time
      description
      location
      date
      home_team_kit
      away_team_kit
      attendance
      stadium {
        opta_name
        name
        location
      }
      competition {
        _id
        opta_ID
        oval_ID
        name
        competition_type
        badge
        secondary_badge
        variables
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      home_interchanges_total
      away_interchanges_total
      home_interchanges_total_gp
      away_interchanges_total_gp
      opta_scores
      kick_predictor
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        name
        short_name
        opta_ID
        code
        variables
        rugby_league_ID
      }
      away_team {
        _id
        name
        short_name
        opta_ID
        code
        variables
        rugby_league_ID
      }
      live_data
      home_phase
      away_phase
      metres_gained
      season {
        _id
        oval_ID
        opta_ID
      }
      commentators {
        first_name
        last_name
      }
      home_sets
      away_sets
      win_predictor
      pundits {
        _id
      }
      officials {
        type
        official {
          first_name
          last_name
        }
      }
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      home_interchanges_total
      away_interchanges_total
      home_interchanges_total_gp
      away_interchanges_total_gp
      opta_scores
      kick_predictor
      season {
        _id
        oval_ID
        opta_ID
      }
      commentators {
        first_name
        last_name
      }
      home_phase
      away_phase
      metres_gained
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      opta_scores
      kick_predictor
      home_sets
      away_sets
      win_predictor
      attendance
      pundits {
        _id
      }
      officials {
        type
        official {
          first_name
          last_name
        }
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
const RightBar = styled.div`
  display: flex;
  width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
`;
const LeftBar = styled.div`
  display: flex;
  width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;
export default function Live({ setSelectedPlayer, selectedPlayer }) {
  let { gameID } = useParams();
  const [game, setGame] = useState();
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);
  const [homeManager, setHomeManager] = useState();
  const [awayManager, setAwayManager] = useState();
  const [showAttendance, setShowAttendance] = useState(false);

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;

    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          updateGraphic("ScoreBug", {
            game: newGame,
          });
          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      let updated_game = JSON.parse(JSON.stringify(data.gameById) ?? [])
      try {
        updated_game.lineup.home  =  updated_game.lineup.home.map(player=>{
          player.squad.position = getPosition({order: player.order})
          return player
        })
      }catch(err) {

      }
      try {
        updated_game.lineup.away  =  updated_game.lineup.away.map(player=>{
          player.squad.position = getPosition({order: player.order})
          return player
        })
      }catch(err) {

      }
      
      setGame(updated_game);
    }
  }, [data]);

  const { goal, setGoal } = useContext(UIContext);
  const {
    previewGraphic,
    animateGraphic,
    graphicInPreview,
    animateOffGraphic,
    updateGraphic,
    clearPVW,
  } = useContext(GraphicsContext);
  const { updateGame } = useContext(APIContext);
  const [homeSubsMade, setHomeSubsMade] = useLocalStorage(
    gameID + "_homeSubsMade",
    0
  );
  const [awaySubsMade, setAwaySubsMade] = useLocalStorage(
    gameID + "_awaySubsMade",
    0
  );

  if (!game) return <div>Loading</div>;

  return (
    game && (
      <Main>
        <Content>
          {!goal && (
            <TeamNames
              setSelectedPlayer={setSelectedPlayer}
              homeTeam={game.home_team}
              awayTeam={game.away_team}
              game={game}
            />
          )}
          {goal && (
            <Goal
              goal={goal}
              setGoal={setGoal}
              cancel={() => {
                updateGame({
                  id: game._id,
                  home_scorers: game.home_scorers.filter((g) => g.player),
                  away_scorers: game.away_scorers.filter((g) => g.player),
                });
                setGoal();
              }}
            />
          )}

          <Switch>
            <Route path="/:gameID/penalties">
              <Penalties game={game} />
            </Route>
            <Route path="/:gameID/clock">
              <ClockEdit game={game} />
            </Route>
            <Route path="/:gameID/goals">
              <Goals
                game={game}
                homeLineup={game?.lineup?.home}
                awayLineup={game?.lineup?.away}
                setGoal={setGoal}
              />
            </Route>
            <Route path="/:gameID/bigscreen">
              <Bigscreen game={game} />
            </Route>
            <Route path="/:gameID/scores">
              <Scores game={game} />
            </Route>
            <Route path="/:gameID/table">
              <TableView game={game} />
            </Route>
            <Route path="/:gameID/kick_predictor">
              <Overall game={game} />
            </Route>
            <Route path="/:gameID/season_rankings">
              <SeasonRankings game={game} />
            </Route>
            <Route path="/:gameID">
              <Game
                setHomeManager={setHomeManager}
                setAwayManager={setAwayManager}
                setGoal={setGoal}
                goal={goal}
                game={game}
                setSelectedPlayer={setSelectedPlayer}
                selectedPlayer={selectedPlayer}
                homeSubsMade={homeSubsMade}
                setHomeSubsMade={setHomeSubsMade}
                awaySubsMade={awaySubsMade}
                setAwaySubsMade={setAwaySubsMade}
              />
              {(window.ENV?.REACT_APP_SKY === "true" ||
                process.env.REACT_APP_SKY === "true") && <ServerStatus />}
            </Route>
          </Switch>
        </Content>

        <RightBar>
          {(window.ENV?.REACT_APP_SKY === "true" ||
            process.env.REACT_APP_SKY === "true") && (
            <RightSideBarSky
              homeManager={homeManager}
              awayManager={awayManager}
              game={game}
              previewGraphic={previewGraphic}
              graphicInPreview={graphicInPreview}
              animateGraphic={animateGraphic}
              animateOffGraphic={animateOffGraphic}
              updateGraphic={updateGraphic}
              selectedPlayer={selectedPlayer}
              clearPreview={clearPVW}
              setShowAttendance={setShowAttendance}
              homeSubsMade={homeSubsMade}
              setHomeSubsMade={setHomeSubsMade}
              awaySubsMade={awaySubsMade}
              setAwaySubsMade={setAwaySubsMade}
            />
          )}
          {window.ENV?.REACT_APP_SKY !== "true" &&
            process.env.REACT_APP_SKY !== "true" && (
              <RightSideBar
                homeManager={homeManager}
                awayManager={awayManager}
                game={game}
                previewGraphic={previewGraphic}
                graphicInPreview={graphicInPreview}
                animateGraphic={animateGraphic}
                animateOffGraphic={animateOffGraphic}
                updateGraphic={updateGraphic}
                selectedPlayer={selectedPlayer}
                clearPreview={clearPVW}
                setShowAttendance={setShowAttendance}
                homeSubsMade={homeSubsMade}
                setHomeSubsMade={setHomeSubsMade}
                awaySubsMade={awaySubsMade}
                setAwaySubsMade={setAwaySubsMade}
              />
            )}
        </RightBar>
        {showAttendance && (
          <Attendance
            game={game}
            updateGame={updateGame}
            onClose={() => {
              setShowAttendance();
            }}
          />
        )}
      </Main>
    )
  );
}
