import moment from "moment";

export class TeamStat {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TeamStat";
    this.scene = "Team Stat";
    this.data = [];
  }

  preview({ options }) {
    let score = this.getScore(options.game);
    this.onAir = false;
    this.options = options;
    this.data = [];

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Pattern Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_pattern"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Pattern Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_pattern"
          ] || "",
      },
    });

    this.data.push({
      name: "Home Team Code",
      value: { text: options.game?.home_team?.code || "" },
    });

    this.data.push({
      name: "Away Team Code",
      value: { text: options.game?.away_team?.code || "" },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });
    this.data.push({
      name: "Home Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.home_team?.variables?.third_badge || "",
      },
    });
    this.data.push({
      name: "Away Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.away_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Home Value",
      value: {
        text: options.stat.home || 0,
      },
    });

    this.data.push({
      name: "Category",
      value: {
        text: options.stat.category || 0,
      },
    });

    this.data.push({
      name: "Away Value",
      value: {
        text: options.stat.away || 0,
      },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Stadium",
      value: { text: options.game?.stadium?.name || "" },
    });

    this.data.push({
      name: "Location",
      value: { text: options.game?.stadium?.location || "" },
    });
    
    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    clearTimeout(this.to);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);

    return { home_score, away_score };
  }
}
